body 
{

  filter: contrast(580%) brightness(100%);
  background: 
	radial-gradient(circle at 50% 50%, rgba(255,255,255,1), rgba(245,225,239,1)),
	url("data:image/svg+xml,%3Csvg viewBox='0 0 258 258' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='4' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");

  
  
  background-repeat: no-repeat;
  background-size: cover; 
  height: 100vh;
}
  
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

h1 {
  margin: 0;
  font-size: 5rem;
}

.location {
  font-size: 1.5rem;
  margin: 0;
}

.button {
  font-size: 1.5rem;
  background: none;
  color: black;
  padding: 1rem 2rem;
  z-index: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  border-style: solid;
  text-decoration: none;
  margin: 1rem;
  margin-top: 5rem;
}

.button:hover {
  border-color: gray;
}